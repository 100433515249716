import { Component, OnInit } from '@angular/core';
import { BowserService } from 'ngx-bowser';

@Component({
  selector: 'app-outdated-browser',
  templateUrl: './outdated-browser.component.html',
  styleUrls: ['./outdated-browser.component.scss']
})
export class OutdatedBrowserComponent implements OnInit {
  public isOutdated = false;

  constructor(private bowser: BowserService) {}

  ngOnInit() {
    const requirements = {
      firefox: '53',
      chrome: '59',
      chromium: '50',
      opera: '65',
      safari: '9'
    };

    this.isOutdated = !this.bowser.check(
      requirements,
      true,
      this.bowser.userAgent
    );
  }
}
